<template>
  <section class="calculator-budget" id="calculator-content-container">
    <div class="header">
      <template v-if="showNewConfig">
        <h2>Trouver votre future maison sur la commune de {{ city.city.toUpperCase() }}</h2>
      </template>
      <template v-else-if="!showNewConfig && showingResults">
        <h2>Le montant estimé de votre projet est de :</h2>
      </template>
      <template v-else-if="$route.name === 'calculator-budget-tenant'">
        <h1>Vous cherchez une location de maison ?</h1>
        <h3>Faites construire votre maison au lieu de la louer !</h3>
        <p>Devenir propriétaire d’un logement neuf offre un confort de vie au quotidien et vous permet également de préparer votre avenir en constituant un patrimoine, pour vous et votre famille.</p>
        <h3 class="subtitle">Propriétaire pour le prix d’un loyer ? Faites le test !</h3>
        <p>Estimez instantanément votre capacité d’emprunt et configurez la maison qui vous correspond.</p>
      </template>
      <template v-else>
        <h1>Calculez votre capacité d'emprunt</h1>
        <h3>À partir de votre future mensualité</h3>
        <p>Estimez instantanément votre capacité d'achat à partir de la mensualité que vous souhaitez. Déterminez ainsi le montant de votre projet pour sélectionner plus facilement les combinaisons maison + terrain qui vous sont les plus adaptées.</p>
      </template>
    </div>
    <div class="grid-container">
      <div class="spinner-container" v-if="loadingResults">
        <app-spinner />
      </div>
      <template v-else>
        <app-error v-if="errors.length" title="Erreur" :messages="errors" @click="errors = []"></app-error>
        <div class="content-container">
          <template v-if="showNewConfig">
            <configurator-search :inputPostalCode="city.postalCodeInseeNumber"/>
          </template>
          <template v-else-if="!showNewConfig && showingResults">
            <calculator-results :results="borrowingCapacity" :originalDuration="oldCalculatorForm.duration" @click="showingResults = false" @showConfig="showConfig"/>
          </template>
          <template v-else>
            <div>
              <CalculatorInputSvg />
            </div>
            <calculator-form @submit="getBorrowingCapacity" :oldForm="oldCalculatorForm" />
          </template>
        </div>
      </template>
    </div>
  </section>
</template>

<script>
import calculatorApi from '@/services/api/calculator';
import ConfiguratorSearch from '@/components/general/ConfiguratorSearch.vue';
import CalculatorInputSvg from '@/assets/img/pages/calculator-input.svg?inline';
import CalculatorForm from './CalculatorForm.vue';
import CalculatorResults from './CalculatorResults.vue';

export default {
  name: 'calculator-budget',
  components: {
    CalculatorInputSvg,
    CalculatorForm,
    CalculatorResults,
    ConfiguratorSearch,
  },
  data() {
    return {
      loadingResults: false,
      showingResults: false,
      borrowingCapacity: null,
      oldCalculatorForm: null,
      errors: [],
      showNewConfig: false,
      city: null,
    };
  },
  watch: {
    showingResults() {
      this.scrollToTop();
    },
  },
  methods: {
    showConfig(city) {
      this.showNewConfig = true;
      this.city = city;
    },
    async getBorrowingCapacity(calculatorForm) {
      this.loadingResults = true;
      this.oldCalculatorForm = calculatorForm;
      try {
        this.borrowingCapacity = await calculatorApi.getBorrowingCapacity({
          ...calculatorForm,
          zipCode: calculatorForm.zipCode.name,
          isOwner: calculatorForm.isOwner === 'true',
          monthlyPaymentMax: +calculatorForm.monthlyPaymentMax,
          incomeN2: +calculatorForm.incomeN2,
          deposit: +calculatorForm.deposit,
          familySize: +calculatorForm.familySize,
          interestRate: +calculatorForm.interestRate,
        });
        this.showingResults = true;
        this.errors = [];
      } catch (error) {
        this.errors.push('Votre capacité de prêt n\'a pas pu être calculée. Veuillez réessayer plus tard.');
      }
      this.loadingResults = false;
    },
    scrollToTop() {
      const top = document.getElementById('calculator-content-container');
      top.scrollIntoView({ behavior: 'smooth', block: 'start' });
    },
  },
};
</script>

<style lang="sass">
.calculator-budget
  background-color: $medium-bg
  .header
    @include header
    background-color: $medium-bg
    text-align: center
    box-shadow: none
    h1
      color: #514f4c
      margin: 1rem 0
      height: fit-content
      text-overflow: unset
      overflow: unset
      white-space: unset
    h2
      color: #514f4c
      margin-top: 48px
    h3
      color: $primary
    p
      color: $body-color
      margin: 0.5rem auto
      max-width: 600px
    .subtitle
      color: black
      padding-top: 16px
  .spinner-container
    @include spinner-container
  .app-error
    margin: 0 auto 2rem
  .content-container
    display: flex
    justify-content: center
    margin: auto
    padding: 2rem 0
    > div:first-child:not(.configurator-search)
      width: 100%
      margin-right: 32px
      svg
        max-width: 480px
    .calculator-form
      width: 100%
    .configurator-search
      max-width: 80%
  @media (max-width: 768px)
    .content-container
      display: block
      margin-top: 0
      > div:first-child
        margin-bottom: 2rem
        svg
          max-width: unset

</style>
